import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch"
import {useLocation} from 'react-router-dom';
import axios from "axios";
import Button from '../components/Button'
import LayoutControls from '../components/blocks/LayoutControls'
import {LuSettings2} from 'react-icons/lu'
import BGLayout from '../components/blocks/BGLayout'
import OnlinePositionsLayout from '../components/blocks/OnlinePositions'

const LayoutFloors = styled.div`
    position: absolute;
    left: 0;
    top: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    z-index: 1;
`

const LayoutOptions = styled.div`
    position: absolute;
    right: 0;
    top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
    z-index: 1;
`
const Home = () => {

  let query = useQuery();
  const [layoutData, setLayoutData] = useState([])
  const [token, setToken] = useState('')
  const [salaId, setSalaId] = useState(0)

  const [newData, setNewData] = useState(null);

  const [selectedFloor, setSelectedFloor] = useState(1);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(null);

  const [view, setView] = useState(1);
  const viewRef = useRef(view);

  useEffect(() => {
    // Actualizar la referencia cuando el estado de 'view' cambia
    viewRef.current = view;
  }, [view]);

  const handleButtonClick = (idx) => {
    setSelectedFloor(idx);
  };


  function setTokenFromQueryParams() {
    const idToken = query.get("id_token")
    const salaId1 = query.get("salaId")

    setToken(idToken)
    setSalaId(salaId1)
  }

  const handleClick = (view) => {
    //console.log('set view : '+ view)
    setView(view)
    getData(view).then(res => {
    })
  };

  async function getData(viewCurrent) {
    const params = {view: viewCurrent}

    try {
      const response = await axios.get(
        'https://api4.wargos.cloud/xpointcore/session/online-layout',
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Sala-Id': salaId
          },
          params: params
        }
      );

      setLayoutData(response.data);
    } catch (error) {
      console.error('Hubo un error:', error.message);
    }
  }

  useEffect(() => {
    if (layoutData) {
      const {frameDimensions, data, positions, layoutKey} = layoutData;
      const updatedPositions = positions?.map((item) => {
        const correspondingMachine = data.find((machine) => machine.positionId === item.id);
        const infoMachine = correspondingMachine && correspondingMachine.positionId === item.id ? correspondingMachine : null;

        return {
          ...item,
          data: infoMachine,
        };
      });

      setNewData({
        frameDimensions,
        positions: updatedPositions,
        layoutKey,
      });
    }
  }, [layoutData]);

  function wParent(confId) {
    window.parent.postMessage({
      'func': 'click',
      'confID': confId
    }, "*");
  }

  if (selectedMachine && selectedMachine.data && selectedMachine.data.confId) {
    wParent(selectedMachine.data.confId);
  }

  useEffect(() => {
    setTokenFromQueryParams()
    const interval = setInterval(() => {
      if (salaId > 0 && token !== '') {
        console.log('view from internal: '+ viewRef.current)
        getData(viewRef.current).then(res => {

        })
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [salaId, token]);

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh'}}>
      <TransformWrapper initialScale={1}>
        {({zoomIn, zoomOut, resetTransform, zoomToElement}) => (
          <>
            <LayoutFloors>
              <Button size={'s'} variant={view === 1 ? 'dark' : 'light'} onClick={() => handleClick(1)}>
                Clientes
              </Button>
              <Button size={'s'} variant={view === 2 ? 'dark' : 'light'} onClick={() => handleClick(2)}>
                Conexion
              </Button>
              <Button size={'s'} variant={view === 3 ? 'dark' : 'light'} onClick={() => handleClick(3)}>
                Tarjetas
              </Button>
            </LayoutFloors>

            <LayoutOptions>
              <Button size='s' onClick={() => setControlsVisible(prev => !prev)}>
                <LuSettings2/>
              </Button>
              <LayoutControls
                zoomIn={() => zoomIn()}
                zoomOut={() => zoomOut()}
                resetTransform={() => resetTransform()}
                controlsVisible={controlsVisible}
              />
            </LayoutOptions>
            <TransformComponent contentStyle={{width: '100%', height: '100%'}}>
              {newData?.frameDimensions?.map(item => (
                <React.Fragment key={item.id}>
                  {selectedFloor === item.floorNumber && (
                    <OnlinePositionsLayout
                      setSelectedMachine={setSelectedMachine}
                      selectedMachine={selectedMachine}
                      zoomToElement={zoomToElement}
                      viewBox={'0 0 ' + item.width + ' ' + item.height}
                      background={<BGLayout url={item.svgFileUrl}/>}
                      positions={newData.positions.filter(pos => pos.floorNumber === item.floorNumber)}
                    />
                  )}
                </React.Fragment>
              ))}
            </TransformComponent>
          </>
        )}

      </TransformWrapper>
    </div>
  )
}

export default Home

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
